import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import MailTo from '../components/mailto';
import LinkOut from '../components/linkout';
import SEO from '../components/seo';
import Header from '../components/header';

const ContactPage = () => (
  <React.Fragment>
    <SEO title="Contact Abramski" path="contact" />
    <Helmet><body className="splash" /></Helmet>
    <Header>
      <nav>
        <ul>
          <li><Link className="btn btn-small" to="/">Home</Link></li>
          <li><Link className="btn btn-small" to="/work">Work</Link></li>
        </ul>
      </nav>
    </Header>
    <Layout>
      <h5>On the Internet</h5>
      <nav className="social">
        <ul>
          <li><LinkOut className="btn" href="https://github.com/robertabramski">Github</LinkOut></li>
          <li><LinkOut className="btn" href="https://linkedin.com/in/robertabramski">LinkedIn</LinkOut></li>
          <li><LinkOut className="btn" href="https://dribbble.com/robertabramski">Dribbble</LinkOut></li>
          <li><LinkOut className="btn" href="https://codepen.io/robertabramski">CodePen</LinkOut></li>
          <li><LinkOut className="btn" href="https://jsfiddle.net/user/robertabramski">JSFiddle</LinkOut></li>
          <li><LinkOut className="btn" href="https://angel.co/robertabramski">AngelList</LinkOut></li>
        </ul>
      </nav>
      <h2 style={{marginBottom: '0', marginTop: '6vh'}}>Em<span className="alt">a</span>il</h2>
      <p style={{marginBottom: '4vmin', fontSize: 'calc(.6em + 4vmin)'}}><MailTo /></p>
    </Layout>
  </React.Fragment>
);

export default ContactPage;
