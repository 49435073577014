import React from 'react';

let email = 'robert@abram.ski';
let mailTo = `mailto:${email}`;

const MailTo = ({children}) => (
  children ? <a href={mailTo}>{children}</a> : <a href={mailTo}>{email}</a>
);

export default MailTo;
